import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Element } from "react-scroll";
import avatar from "../images/avatar.PNG";
class Skills extends Component {
  render() {
    return (
      <Element name="section2">
        <div className="Skills">
          <div className="u-center">
            <section className="skills">
              <ScrollAnimation
                animateIn="slide-in-top"
                duration={0.7}
                animateOnce={true}
              >
                <h1 className="section-heading">ABOUT</h1>
              </ScrollAnimation>
              <div className="skills-flex-container">
                <div className="profile">
                  <div className="profile-img">
                    <ScrollAnimation
                      animateIn="slide-in-left"
                      duration={0.7}
                      animateOnce={true}
                    >
                      <img src={avatar} alt="Deepak Moses"></img>
                    </ScrollAnimation>
                  </div>
                  <ScrollAnimation
                    animateIn="slide-in-left"
                    duration={0.7}
                    delay={100}
                    animateOnce={true}
                  >
                    <div className="profile-desc">
                      I'm a Web Developer based in Chennai,India . Design is my
                      driving passion. Everything in my life is affected by it.
                      Understanding how great design works and showing others
                      the importance of well thought out, beautiful design is
                      what I get the most joy from.
                    </div>
                    <div className="button-section">
                      <a
                        href="https://drive.google.com/file/d/1iux1hCIVAAFzOjUHQdN5dgmihwuqEZuw/view"
                        target="_blank"
                        className="cv"
                        rel="noopener noreferrer"
                      >
                        RESUME
                      </a>
                    </div>
                  </ScrollAnimation>
                </div>
                <ScrollAnimation
                  animateIn="bounce-in-right"
                  duration={0.7}
                  delay={300}
                  animateOnce={true}
                >
                  <div className="skillBars">
                    <div className="skill">
                      <h3 className="skill_type">HTML5</h3>
                      <div className="skill_outer-bar">
                        <div className="skill_inner-bar skill_inner-bar-100"></div>
                      </div>
                    </div>

                    <div className="skill">
                      <h3 className="skill_type">CSS3</h3>
                      <div className="skill_outer-bar">
                        <div className="skill_inner-bar skill_inner-bar-90"></div>
                      </div>
                    </div>
                    <div className="skill">
                      <h3 className="skill_type">JAVASCRIPT</h3>
                      <div className="skill_outer-bar">
                        <div className="skill_inner-bar skill_inner-bar-80"></div>
                      </div>
                    </div>
                    <div className="skill">
                      <h3 className="skill_type">REACTJS</h3>
                      <div className="skill_outer-bar">
                        <div className="skill_inner-bar skill_inner-bar-90"></div>
                      </div>
                    </div>
                    <div className="skill">
                      <h3 className="skill_type">ANGULAR</h3>
                      <div className="skill_outer-bar">
                        <div className="skill_inner-bar skill_inner-bar-70"></div>
                      </div>
                    </div>
                    <div className="skill">
                      <h3 className="skill_type">NODEJS</h3>
                      <div className="skill_outer-bar">
                        <div className="skill_inner-bar skill_inner-bar-80"></div>
                      </div>
                    </div>
                    <div className="skill">
                      <h3 className="skill_type">JQUERY</h3>
                      <div className="skill_outer-bar">
                        <div className="skill_inner-bar skill_inner-bar-90"></div>
                      </div>
                    </div>
                    {/* <div className="skill">
                      <h3 className="skill_type">MYSQL</h3>
                      <div className="skill_outer-bar">
                        <div className="skill_inner-bar skill_inner-bar-80"></div>
                      </div>
                    </div> */}
                    <div className="skill">
                      <h3 className="skill_type">PHOTOSHOP</h3>
                      <div className="skill_outer-bar">
                        <div className="skill_inner-bar skill_inner-bar-70"></div>
                      </div>
                    </div>
                    <div className="skill">
                      <h3 className="skill_type">AFTER EFFECTS</h3>
                      <div className="skill_outer-bar">
                        <div className="skill_inner-bar skill_inner-bar-50"></div>
                      </div>
                    </div>
                    <div className="skill">
                      <h3 className="skill_type">ILLUSTRATOR</h3>
                      <div className="skill_outer-bar">
                        <div className="skill_inner-bar skill_inner-bar-80"></div>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="skill-cards">
                <ScrollAnimation
                  animateIn="slide-in-bottom"
                  duration={0.7}
                  delay={100}
                  animateOnce={true}
                >
                  <div className="skill-card">
                    <i className="fas fa-mobile fa-7x"></i>
                    <h2>Responsive</h2>
                    My layouts will be responsive across any device/browser
                  </div>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="slide-in-bottom"
                  duration={0.7}
                  delay={200}
                  animateOnce={true}
                >
                  <div className="skill-card">
                    <i className="fas fa-tachometer-alt fa-7x"></i>
                    <h2>Fast</h2>
                    Smooth and snappy interactions and fast load times
                  </div>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="slide-in-bottom"
                  duration={0.7}
                  delay={300}
                  animateOnce={true}
                >
                  <div className="skill-card">
                    <i className="fas fa-blind fa-7x"></i>
                    <h2>Accessible</h2>A good website is a one that is
                    accessible to everyone
                  </div>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="slide-in-bottom"
                  duration={0.7}
                  delay={400}
                  animateOnce={true}
                >
                  <div className="skill-card">
                    <i className="fas fa-hand-pointer fa-7x"></i>
                    <h2>Dynamic</h2>
                    Why static when dynamic pages are more fun to interact with
                  </div>
                </ScrollAnimation>
              </div>
            </section>
          </div>
        </div>
      </Element>
    );
  }
}

export default Skills;
