import React, { Component } from "react";
import { Element } from "react-scroll";
import Gallery from "./Gallery";
import ScrollAnimation from "react-animate-on-scroll";

class Skills extends Component {
  render() {
    return (
      <Element name="section3" className="section3">
        <ScrollAnimation
          animateIn="slide-in-top"
          duration={0.7}
          animateOnce={true}
        >
          <h1 className="section-heading">Work</h1>
        </ScrollAnimation>
        <Gallery />
      </Element>
    );
  }
}

export default Skills;
