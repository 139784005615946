import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";

class Gallery extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="gallery">
          <ScrollAnimation
            animateIn="slide-in-right"
            duration={0.3}
            delay={100}
            animateOnce={true}
          >
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 556.91 372.56"
            >
              <defs>
                <linearGradient
                  id="linear-gradient"
                  x1="289.35"
                  y1="362.02"
                  x2="290.8"
                  y2="235.9"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset={0} stopColor="#010101" stopOpacity={0} />
                  <stop offset="0.95" stopColor="#010101" />
                </linearGradient>
                <linearGradient
                  id="linear-gradient-2"
                  x1="293.17"
                  y1="0.57"
                  x2="294.84"
                  y2="618.77"
                  xlinkHref="#linear-gradient"
                />
                <linearGradient
                  id="linear-gradient-3"
                  x1="290.5"
                  y1="281.91"
                  x2="290.63"
                  y2="326.89"
                  xlinkHref="#linear-gradient"
                />
                <linearGradient
                  id="linear-gradient-4"
                  x1="377.03"
                  y1="142.55"
                  x2="385.74"
                  y2="162.36"
                  xlinkHref="#linear-gradient"
                />
                <linearGradient
                  id="linear-gradient-5"
                  x1="365.5"
                  y1="153.2"
                  x2="360.26"
                  y2="157.87"
                  xlinkHref="#linear-gradient"
                />
                <linearGradient
                  id="linear-gradient-6"
                  x1="393.05"
                  y1="154.73"
                  x2="366.59"
                  y2="177.13"
                  xlinkHref="#linear-gradient"
                />
                <linearGradient
                  id="linear-gradient-7"
                  x1="326.66"
                  y1="108.3"
                  x2="423.88"
                  y2="242.67"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset={0} stopColor="#fff" stopOpacity={0} />
                  <stop offset="0.13" stopColor="#fff" stopOpacity="0.23" />
                  <stop offset="0.23" stopColor="#fff" stopOpacity="0.41" />
                  <stop offset="0.35" stopColor="#fff" stopOpacity="0.59" />
                  <stop offset="0.48" stopColor="#fff" stopOpacity="0.74" />
                  <stop offset="0.6" stopColor="#fff" stopOpacity="0.85" />
                  <stop offset="0.72" stopColor="#fff" stopOpacity="0.93" />
                  <stop offset="0.84" stopColor="#fff" stopOpacity="0.98" />
                  <stop offset="0.95" stopColor="#fff" />
                </linearGradient>
                <linearGradient
                  id="linear-gradient-8"
                  x1="420.73"
                  y1="210.31"
                  x2="399.73"
                  y2="206.62"
                  xlinkHref="#linear-gradient"
                />
                <linearGradient
                  id="linear-gradient-9"
                  x1="413.9"
                  y1="212.71"
                  x2="380.98"
                  y2="205.44"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset={0} stopColor="#010101" stopOpacity={0} />
                  <stop offset="0.55" stopColor="#010101" stopOpacity="0.26" />
                  <stop offset="0.95" stopColor="#010101" />
                </linearGradient>
                <linearGradient
                  id="linear-gradient-10"
                  x1="395.97"
                  y1="103.3"
                  x2="394.24"
                  y2="123.52"
                  xlinkHref="#linear-gradient"
                />
                <linearGradient
                  id="linear-gradient-11"
                  x1="398.06"
                  y1={120}
                  x2="393.87"
                  y2="119.82"
                  xlinkHref="#linear-gradient"
                />
                <linearGradient
                  id="linear-gradient-12"
                  x1="380.79"
                  y1="187.64"
                  x2="436.85"
                  y2="125.12"
                  xlinkHref="#linear-gradient"
                />
                <linearGradient
                  id="linear-gradient-13"
                  x1="393.7"
                  y1="225.65"
                  x2="398.03"
                  y2="260.72"
                  xlinkHref="#linear-gradient"
                />
                <linearGradient
                  id="linear-gradient-14"
                  x1="412.88"
                  y1="223.21"
                  x2="417.21"
                  y2="258.32"
                  xlinkHref="#linear-gradient"
                />
                <linearGradient
                  id="linear-gradient-15"
                  x1="358.64"
                  y1="82.15"
                  x2="319.33"
                  y2="161.78"
                  xlinkHref="#linear-gradient"
                />
                <linearGradient
                  id="linear-gradient-16"
                  x1="347.18"
                  y1="76.61"
                  x2="308.05"
                  y2="155.85"
                  xlinkHref="#linear-gradient"
                />
              </defs>
              <path
                d="M234.74,7.63C189.52-2.28,137.09,5.45,106.53,41,93.41,56.2,85.35,75.22,78,94.08c-9.53,24.35-18.49,49.38-33.82,70.41-14,19.27-33.47,35.39-40.73,58.24-10.69,33.67,8.72,69.4,31,96.48,12,14.53,25.39,28.44,42.2,36.5,14.3,6.84,30.24,9.07,45.94,10.8,90.16,9.95,181.17,6.14,271.78,2.33,25.49-1.07,51.09-2.16,76.15-7,19.76-3.84,39.45-10.21,55.76-22.25s28.92-30.42,30.8-50.9c1.68-18.36-5.22-36.49-14.3-52.41C535.5,223.54,526.55,211,525,196.4c-1.41-13.19,3.42-26.13,5.75-39.18,7.16-40.06-11.58-83.67-45.28-105.35-22.69-14.59-49.95-19.29-76.41-23.68L346.33,17.78c-18.59-3.09-34-.87-52.36.23C273.78,19.21,254.22,11.9,234.74,7.63Z"
                transform="translate(-0.4 -3.66)"
                fill="#7a72ff"
                opacity="0.24"
                style={{ isolation: "isolate" }}
              />
              <polygon
                points="350.33 354.46 228.29 354.46 249.58 311.32 334.18 311.32 350.33 354.46"
                fill="#7a72ff"
              />
              <polygon
                points="347.87 348.18 231.15 348.18 249.58 311.32 334.18 311.32 347.87 348.18"
                fill="url(#linear-gradient)"
              />
              <rect
                x="218.31"
                y="351.35"
                width="143.3"
                height="10.45"
                fill="#7a72ff"
              />
              <path
                d="M469.9,65.55V312.26a11.38,11.38,0,0,1-11.28,11.49h-330a11.4,11.4,0,0,1-11.3-11.49V65.55A11.46,11.46,0,0,1,128.62,54h330A11.44,11.44,0,0,1,469.9,65.55Z"
                transform="translate(-0.4 -3.66)"
                fill="#7a72ff"
              />
              <path
                d="M469.91,65.55V271.86H117.33V65.55A11.43,11.43,0,0,1,128.62,54h330A11.43,11.43,0,0,1,469.91,65.55Z"
                transform="translate(-0.4 -3.66)"
                fill="url(#linear-gradient-2)"
              />
              <rect
                x="129.87"
                y="64.88"
                width="328.17"
                height="194.36"
                fill="#fff"
              />
              <path
                d="M368.41,134c-3.21,15.59-16.74,27.3-32.94,27.3-18.64,0-33.73-15.43-33.73-34.46a34.83,34.83,0,0,1,2.65-13.44,35.23,35.23,0,0,0-.73,7.17c0,19,15.09,34.46,33.7,34.46A33.68,33.68,0,0,0,368.41,134Z"
                transform="translate(-0.4 -3.66)"
                fill="#231f20"
                opacity="0.2"
              />
              <ellipse
                cx="290.54"
                cy="293.73"
                rx="7.78"
                ry="7.96"
                fill="url(#linear-gradient-3)"
              />
              <path
                d="M396.79,145.06l-6.13,1.54,1.2,18.51h-31l-1.29-20.05-6.11,1.54,11.31,177.15H371l-1.05-16.17h31L402,323.75h6.19ZM361.2,171h31l1.38,21.43h-31Zm1.74,27.3h31l1.38,21.46h-31Zm1.75,27.33h31l1.38,21.47h-31Zm3.18,49.6L366.43,253h31l1.39,21.43H368Zm1.69,26.5-1.39-21.46h31l1.38,21.46Z"
                transform="translate(-0.4 -3.66)"
                fill="#231f20"
                opacity="0.2"
              />
              <path
                d="M407.1,250.22a30.87,30.87,0,0,0-7.25-21.79c-1,3.62-.19,7.45-.16,11.2s-1,8-4.26,9.77c-3.53-2.58-4.43-7.46-5.06-11.84q-3.44-24.21-6.89-48.42c-2.31,1.12-5.16.17-7.05-1.6s-3-4.2-4.07-6.57c-4.81-10.6-9.7-21.57-10.07-33.24,1.81,2.48,3.68,5,6.23,6.68s6,2.3,8.62.77a12.1,12.1,0,0,0,3.89-4.4c1.81-2.95,3.54-6.26,3.12-9.72a22.61,22.61,0,0,0-1.6-5.07c-2.31-6.16-2.66-12.88-3-19.47a5.28,5.28,0,0,1,.36-2.74,4.07,4.07,0,0,1,2.42-1.74,11.13,11.13,0,0,1,13.48,6.68c1.12,3.13.81,6.57,1,9.89.68,9.88,6.15,18.75,8.07,28.46,1.51,7.62.78,15.49.05,23.23l-4.28,45.52"
                transform="translate(-0.4 -3.66)"
                fill="#231f20"
                opacity="0.07"
              />
              <path
                d="M354,146.63,412.5,373.92a3,3,0,0,0,3.67,2.2h0a3.09,3.09,0,0,0,2.15-3.76l-9-34.82h31l9.36,36.38a3,3,0,0,0,3.67,2.2h0a3.09,3.09,0,0,0,2.15-3.76l-58.49-227.3-5.81,1.57,4.82,18.75h-31l-5.23-20.32ZM395.1,282.14h31l5.6,21.75h-31ZM388,254.43h31l5.59,21.75H393.78V277Zm-7.12-27.7h31l5.6,21.75h-31ZM373.72,199h31l5.6,21.75h-31Zm-7.13-27.7h31l5.6,21.75h-31Zm72.26,160.26h-31l-5.6-21.75h31Z"
                transform="translate(-0.4 -3.66)"
                fill="#231f20"
              />
              <path
                d="M386.43,148.92a10.11,10.11,0,0,1-4.47,4.66l-8.25,5.2a2.34,2.34,0,0,0-.87.81,2.26,2.26,0,0,0,.26,1.86,12.19,12.19,0,0,0,1.76,3.06,3.91,3.91,0,0,0,3.05,1.48,5.91,5.91,0,0,0,2.82-1.24l9.39-6.36a4.81,4.81,0,0,0,1.68-1.58,4.46,4.46,0,0,0,.42-1.84,18.65,18.65,0,0,0-1.26-8.59C389.14,142.38,387.73,146.66,386.43,148.92Z"
                transform="translate(-0.4 -3.66)"
                fill="#7a72ff"
              />
              <path
                d="M386.43,148.92a10.11,10.11,0,0,1-4.47,4.66l-8.25,5.2a2.34,2.34,0,0,0-.87.81,2.26,2.26,0,0,0,.26,1.86,12.19,12.19,0,0,0,1.76,3.06,3.91,3.91,0,0,0,3.05,1.48,5.91,5.91,0,0,0,2.82-1.24l9.39-6.36a4.81,4.81,0,0,0,1.68-1.58,4.46,4.46,0,0,0,.42-1.84,18.65,18.65,0,0,0-1.26-8.59C389.14,142.38,387.73,146.66,386.43,148.92Z"
                transform="translate(-0.4 -3.66)"
                fill="url(#linear-gradient-4)"
              />
              <path
                d="M369.8,163.38h0a2.86,2.86,0,0,1-4.08-.48L356.49,151a3,3,0,0,1,.48-4.17h0a2.85,2.85,0,0,1,4.07.49l9.23,11.93A3,3,0,0,1,369.8,163.38Z"
                transform="translate(-0.4 -3.66)"
                fill="#7a72ff"
              />
              <path
                d="M369.8,163.38h0a2.86,2.86,0,0,1-4.08-.48L356.49,151a3,3,0,0,1,.48-4.17h0a2.85,2.85,0,0,1,4.07.49l9.23,11.93A3,3,0,0,1,369.8,163.38Z"
                transform="translate(-0.4 -3.66)"
                fill="url(#linear-gradient-5)"
              />
              <path
                d="M386.91,185h0a5.21,5.21,0,0,1-7.43-.88l-16.84-21.79a5.5,5.5,0,0,1,.87-7.6h0a5.22,5.22,0,0,1,7.43.89l16.84,21.78A5.48,5.48,0,0,1,386.91,185Z"
                transform="translate(-0.4 -3.66)"
                fill="#7a72ff"
              />
              <path
                d="M386.91,185h0a5.21,5.21,0,0,1-7.43-.88l-16.84-21.79a5.5,5.5,0,0,1,.87-7.6h0a5.22,5.22,0,0,1,7.43.89l16.84,21.78A5.48,5.48,0,0,1,386.91,185Z"
                transform="translate(-0.4 -3.66)"
                fill="url(#linear-gradient-6)"
              />
              <path
                d="M336.78,86.21c-19.44,0-35.28,16.18-35.28,36.07s15.84,36.08,35.28,36.08,35.27-16.18,35.27-36.08S356.23,86.21,336.78,86.21Zm.38,67.86c-17.12,0-31-14.24-31-31.74s13.92-31.75,31-31.75,31,14.25,31,31.75S354.27,154.07,337.16,154.07Z"
                transform="translate(-0.4 -3.66)"
                fill="#7a72ff"
              />
              <path
                d="M336.78,86.21c-19.44,0-35.28,16.18-35.28,36.07s15.84,36.08,35.28,36.08,35.27-16.18,35.27-36.08S356.23,86.21,336.78,86.21Zm.38,67.86c-17.12,0-31-14.24-31-31.74s13.92-31.75,31-31.75,31,14.25,31,31.75S354.27,154.07,337.16,154.07Z"
                transform="translate(-0.4 -3.66)"
                fill="url(#linear-gradient-7)"
              />
              <path
                d="M412.08,209.31c-1.25,7.8.56,15.87,3.86,23a12.72,12.72,0,0,1,1.41,4.1,3.79,3.79,0,0,1-1.66,3.75c-1.51.81-3.37,0-4.74-1.08-4-3-6.43-7.72-8.33-12.41a87.81,87.81,0,0,1-6.08-24.8,30.48,30.48,0,0,1,.62-11.39,25.46,25.46,0,0,1,4.59-8.37,14,14,0,0,1,4.06-3.78,8.29,8.29,0,0,1,8.2.44c3,1.85,3.37,4,3.35,7.32C417.32,194.26,413.34,201.4,412.08,209.31Z"
                transform="translate(-0.4 -3.66)"
                fill="#606161"
              />
              <path
                d="M411.94,209.1c-1.26,7.8.56,15.87,3.86,23a12.59,12.59,0,0,1,1.41,4.09,3.79,3.79,0,0,1-1.66,3.75c-1.51.81-3.37,0-4.74-1.08-4-3-6.43-7.72-8.34-12.41a88.07,88.07,0,0,1-6.07-24.79,30.26,30.26,0,0,1,.62-11.39,25.41,25.41,0,0,1,4.59-8.38,14.18,14.18,0,0,1,4.06-3.78,8.32,8.32,0,0,1,8.2.44c3,1.86,3.37,4,3.35,7.33C417.18,194,413.2,201.19,411.94,209.1Z"
                transform="translate(-0.4 -3.66)"
                fill="url(#linear-gradient-8)"
              />
              <path
                d="M402.84,207.73a28.88,28.88,0,0,0-3.16,5.87,21.89,21.89,0,0,0-.78,6.91,113.75,113.75,0,0,0,.68,11.82c.25,2.22.55,4.58-.29,6.6s-3.34,3.37-4.93,2a5.47,5.47,0,0,1-1.24-2,76.44,76.44,0,0,1-5.56-41.17c.67-4.63,1.8-9.29,4.19-13.14s6.23-6.83,10.44-7c2.26-.09,6.08.78,7.56,2.93s.47,5.89,0,8.47A53.35,53.35,0,0,1,402.84,207.73Z"
                transform="translate(-0.4 -3.66)"
                fill="#606161"
              />
              <path
                d="M402.84,207.73a28.88,28.88,0,0,0-3.16,5.87,21.89,21.89,0,0,0-.78,6.91,113.75,113.75,0,0,0,.68,11.82c.25,2.22.55,4.58-.29,6.6s-3.34,3.37-4.93,2a5.47,5.47,0,0,1-1.24-2,76.44,76.44,0,0,1-5.56-41.17c.67-4.63,1.8-9.29,4.19-13.14s6.23-6.83,10.44-7c2.26-.09,6.08.78,7.56,2.93s.47,5.89,0,8.47A53.35,53.35,0,0,1,402.84,207.73Z"
                transform="translate(-0.4 -3.66)"
                fill="url(#linear-gradient-9)"
              />
              <path
                d="M366.54,161.44c-.42.69.62,1.75,0,2.33-.35.36-.95.1-1.44.2a1.37,1.37,0,0,0-.92,1.5,4.18,4.18,0,0,0,.76,1.76l1.1,1.72a3.35,3.35,0,0,0,.54.7,2.58,2.58,0,0,0,.79.48,4.11,4.11,0,0,0,3.67-.39,2.06,2.06,0,0,0,.6-.54,2,2,0,0,0,.33-.7,5.59,5.59,0,0,0-1.26-4.63C369.9,162.94,367.84,161.34,366.54,161.44Z"
                transform="translate(-0.4 -3.66)"
                fill="#c8a59c"
              />
              <path
                d="M386.09,112.2a22.56,22.56,0,0,0-.15,7.65l.47,4.88a6.86,6.86,0,0,0,.91,3.35c1.19,1.73,3.57,1.94,5.63,2l.4,4.05a3.68,3.68,0,0,0,.38,1.54,2.66,2.66,0,0,0,2.82,1c3-.55,4.7-3.73,5.54-6.69a28.77,28.77,0,0,0,.61-12.85,14.89,14.89,0,0,0-3.88-8.23C394.51,104.76,387.61,106.22,386.09,112.2Z"
                transform="translate(-0.4 -3.66)"
                fill="#c8a59c"
              />
              <path
                d="M391.94,101.3c-2.36-.39-5-1-7,.34a5.35,5.35,0,0,0-1.75,6.14,7.83,7.83,0,0,0,4.83,4.5c2.21.79,5,1.12,5.91,3.33.57,1.46,0,3.27.85,4.58l2-3.31a.57.57,0,0,1,.27-.28.52.52,0,0,1,.43.08,3.55,3.55,0,0,1-.25,6.36,6.39,6.39,0,0,1,2.44,6.12,3.17,3.17,0,0,0,3.45-.59,8.44,8.44,0,0,0,2-3.13,37.52,37.52,0,0,0,1.7-4.73,15.53,15.53,0,0,0,.74-6.4,7.06,7.06,0,0,0-3.27-5.29,3.06,3.06,0,0,1-2.35-3.13C400.9,100.54,395.93,102,391.94,101.3Z"
                transform="translate(-0.4 -3.66)"
                fill="#606161"
              />
              <path
                d="M391.94,101.3c-2.36-.39-5-1-7,.34a5.35,5.35,0,0,0-1.75,6.14,7.83,7.83,0,0,0,4.83,4.5c2.21.79,5,1.12,5.91,3.33.57,1.46,0,3.27.85,4.58l2-3.31a.57.57,0,0,1,.27-.28.52.52,0,0,1,.43.08,3.55,3.55,0,0,1-.25,6.36,6.39,6.39,0,0,1,2.44,6.12,3.17,3.17,0,0,0,3.45-.59,8.44,8.44,0,0,0,2-3.13,37.52,37.52,0,0,0,1.7-4.73,15.53,15.53,0,0,0,.74-6.4,7.06,7.06,0,0,0-3.27-5.29,3.06,3.06,0,0,1-2.35-3.13C400.9,100.54,395.93,102,391.94,101.3Z"
                transform="translate(-0.4 -3.66)"
                fill="url(#linear-gradient-10)"
              />
              <path
                d="M396.25,120.94a.79.79,0,0,0,.4-.56,1.7,1.7,0,0,0,0-.5,2.3,2.3,0,0,1,0-1,1.32,1.32,0,0,1,.61-.78.32.32,0,0,1,.19,0,.33.33,0,0,1,.16,0l.33.14a.62.62,0,0,1,.25.17.66.66,0,0,1,.08.21,3.5,3.5,0,0,1-.69,2.94.91.91,0,0,1-.23.21.85.85,0,0,1-.38.09,1.74,1.74,0,0,1-.88-.08c-.16-.09-.52-.3-.44-.52S396.08,121,396.25,120.94Z"
                transform="translate(-0.4 -3.66)"
                fill="url(#linear-gradient-11)"
              />
              <path
                d="M417.81,175.92c-.06,2.18-.24,4.46-1.39,6.27-1.92,3.14-6,4.06-9.62,4.06a23.31,23.31,0,0,1-4.87-.46,15.29,15.29,0,0,1-3.45-1,12.29,12.29,0,0,1-6.16-5.69,20,20,0,0,1-1.57-6.7c-.42-3.53-.84-7.07-1.17-10.64-.36-4-.6-7.93-.63-11.89a32.84,32.84,0,0,1,1.05-9.38,13.77,13.77,0,0,1,5.14-7.6c2-1.38,5.89-3.13,8.42-2.76,2.82.4,4.72,4.18,5.83,6.51A84.17,84.17,0,0,1,417.81,175.92Z"
                transform="translate(-0.4 -3.66)"
                fill="#7a72ff"
              />
              <path
                d="M402.81,167.59a46.51,46.51,0,0,0-.63,10.27c.18,2.3,1.44,5.16.18,7.34a3.56,3.56,0,0,1-.43.59,15.29,15.29,0,0,1-3.45-1,12.29,12.29,0,0,1-6.16-5.69,20,20,0,0,1-1.57-6.7c-.42-3.53-.84-7.07-1.17-10.64.81-3.29,3.31-5.93,5.56-8.45,3.22-3.54,6.31-7.38,7.76-12a5,5,0,0,1,2.52,3.69,15.3,15.3,0,0,1-.15,4.67Q404,158.58,402.81,167.59Z"
                transform="translate(-0.4 -3.66)"
                fill="url(#linear-gradient-12)"
              />
              <path
                d="M396.64,137.73c-4.18,3.89-5.36,10-7.94,15.15a8.51,8.51,0,0,1-1.34,2.08c-1.22,1.32-3,1.88-4.68,2.48a49.41,49.41,0,0,0-9.81,4.77,7.66,7.66,0,0,0-3.47,3.6c-.79,2.3.64,4.95,2.73,6.14a11.27,11.27,0,0,0,7,1,45.42,45.42,0,0,0,13.09-4.06,16.86,16.86,0,0,0,4-2.33,18.38,18.38,0,0,0,2.77-3.25,61,61,0,0,0,3.77-5.74,32.7,32.7,0,0,0,4-12.8,12.66,12.66,0,0,0-.62-6.12C404.1,133.82,399.8,134.79,396.64,137.73Z"
                transform="translate(-0.4 -3.66)"
                fill="#7a72ff"
              />
              <path
                d="M400.34,242.83c.28,1,.52,2.29-.13,3.15a2,2,0,0,1-1.32.75,2.84,2.84,0,0,1-2-.55,5.15,5.15,0,0,1-1.4-1.67,8,8,0,0,1-1-3.51,2.07,2.07,0,0,1,.18-1.1,2,2,0,0,1,1.16-.85c.9-.34,2.6-.93,3.37-.07S400.06,241.75,400.34,242.83Z"
                transform="translate(-0.4 -3.66)"
                fill="#c8a59c"
              />
              <path
                d="M419,240c.56.93,1.13,2,.74,3.07a2,2,0,0,1-1.05,1.09,2.85,2.85,0,0,1-2.11.06,4.91,4.91,0,0,1-1.8-1.2,7.75,7.75,0,0,1-1.9-3.08,2.12,2.12,0,0,1-.13-1.11,2,2,0,0,1,.87-1.15c.77-.58,2.24-1.64,3.22-1S418.44,239,419,240Z"
                transform="translate(-0.4 -3.66)"
                fill="#c8a59c"
              />
              <path
                d="M398.17,245.05a3.58,3.58,0,0,1-1.84.16,2.69,2.69,0,0,0-.94,0,2.88,2.88,0,0,0-.74.38l-3.46,2.22a2,2,0,0,0-1,1,1.43,1.43,0,0,0,.69,1.54,3.91,3.91,0,0,0,1.72.43,32.94,32.94,0,0,0,8.12-.28,2.07,2.07,0,0,0,1.09-.41,1.91,1.91,0,0,0,.47-.77,5.59,5.59,0,0,0,.32-3.33,3.59,3.59,0,0,0-1.8-2.47C399.76,243.25,399,244.66,398.17,245.05Z"
                transform="translate(-0.4 -3.66)"
                fill="#7a72ff"
              />
              <path
                d="M417,242.64a3.71,3.71,0,0,1-1.81.38,2.63,2.63,0,0,0-.93.15,2.72,2.72,0,0,0-.69.47l-3.16,2.63a2.09,2.09,0,0,0-.85,1.13,1.44,1.44,0,0,0,.87,1.45,3.73,3.73,0,0,0,1.76.21,32.66,32.66,0,0,0,8-1.28,2.2,2.2,0,0,0,1-.55,2.06,2.06,0,0,0,.38-.82,5.67,5.67,0,0,0-.08-3.34c-.31-.84-1.14-2.06-2.08-2.23C418.38,240.65,417.79,242.14,417,242.64Z"
                transform="translate(-0.4 -3.66)"
                fill="#7a72ff"
              />
              <path
                d="M398.17,245.05a3.58,3.58,0,0,1-1.84.16,2.69,2.69,0,0,0-.94,0,2.88,2.88,0,0,0-.74.38l-3.46,2.22a2,2,0,0,0-1,1,1.43,1.43,0,0,0,.69,1.54,3.91,3.91,0,0,0,1.72.43,32.94,32.94,0,0,0,8.12-.28,2.07,2.07,0,0,0,1.09-.41,1.91,1.91,0,0,0,.47-.77,5.59,5.59,0,0,0,.32-3.33,3.59,3.59,0,0,0-1.8-2.47C399.76,243.25,399,244.66,398.17,245.05Z"
                transform="translate(-0.4 -3.66)"
                fill="url(#linear-gradient-13)"
              />
              <path
                d="M417,242.64a3.71,3.71,0,0,1-1.81.38,2.63,2.63,0,0,0-.93.15,2.72,2.72,0,0,0-.69.47l-3.16,2.63a2.09,2.09,0,0,0-.85,1.13,1.44,1.44,0,0,0,.87,1.45,3.73,3.73,0,0,0,1.76.21,32.66,32.66,0,0,0,8-1.28,2.2,2.2,0,0,0,1-.55,2.06,2.06,0,0,0,.38-.82,5.67,5.67,0,0,0-.08-3.34c-.31-.84-1.14-2.06-2.08-2.23C418.38,240.65,417.79,242.14,417,242.64Z"
                transform="translate(-0.4 -3.66)"
                fill="url(#linear-gradient-14)"
              />
              <rect
                x="166.35"
                y="106.32"
                width="88.45"
                height="6.49"
                rx="3.21"
                fill="#7a72ff"
                opacity="0.36"
              />
              <rect
                x="194.92"
                y="125.15"
                width="88.45"
                height="6.49"
                rx="3.21"
                fill="#7a72ff"
                opacity="0.36"
              />
              <rect
                x="170.37"
                y="143.76"
                width="88.45"
                height="6.49"
                rx="3.21"
                fill="#7a72ff"
                opacity="0.36"
              />
              <path
                d="M344.55,116.11c1-1,2-1.83,3-2.69.22-.2.49-.51.73-.5a2,2,0,0,1,1.26.38.92.92,0,0,1-.16,1.38c-.95.89-1.91,1.77-2.93,2.71a9.57,9.57,0,0,1,.77,1,16.61,16.61,0,0,1,1.58,3.08,1.46,1.46,0,0,0,1.65,1.22c1.41,0,2.82.11,4.23.19.88,0,1.28.46,1.25,1.22s-.48,1.17-1.33,1.14c-1.74-.06-3.47-.16-5.3-.25a10.38,10.38,0,0,1-2.88,5.79l-11.47-13.09L333,119.46l11.57,13.25a8.11,8.11,0,0,1-5.13,2.12c-.86,0-1.14.32-1.09,1.19.08,1.29,0,2.58,0,3.87,0,1.13-.5,1.79-1.35,1.78s-1.2-.56-1.2-1.8c0-1.5,0-3,0-4.51a1.13,1.13,0,0,0-.68-1.13c-1.3-.8-2.53-1.73-3.95-2.72L329.55,133l-1,.92c-.84.76-1.53.84-2.05.26s-.38-1.22.49-2l2.88-2.65c-1.27-1.44-2.48-2.84-3.72-4.2a1.32,1.32,0,0,0-.83-.24c-1.26-.09-2.52-.15-3.78-.22-.92,0-1.37-.46-1.36-1.24s.43-1.14,1.41-1.11c1.29,0,2.58.17,3.87.17a2.08,2.08,0,0,0,1.26-.49q5.61-5.07,11.16-10.2a2,2,0,0,0,.52-1.17c.13-1.28.15-2.58.22-3.86,0-.24,0-.58.17-.7.37-.29.85-.7,1.23-.64a1.39,1.39,0,0,1,.87,1,27.75,27.75,0,0,1-.15,3.86,2.29,2.29,0,0,0,.66,2C342.47,113.65,343.47,114.87,344.55,116.11Z"
                transform="translate(-0.4 -3.66)"
                fill="#7a72ff"
              />
              <path
                d="M335,111l-9.22,8.44a6,6,0,0,1-1.53-5.16A6.16,6.16,0,0,1,335,111Z"
                transform="translate(-0.4 -3.66)"
                fill="#7a72ff"
              />
              <path
                d="M344.57,116.09c1-1,2-1.83,3-2.69.22-.2.49-.51.73-.5a2,2,0,0,1,1.26.38.92.92,0,0,1-.16,1.38c-.95.89-1.91,1.77-2.93,2.71a9.57,9.57,0,0,1,.77,1,16.61,16.61,0,0,1,1.58,3.08,1.45,1.45,0,0,0,1.65,1.21c1.41,0,2.82.12,4.23.2.88,0,1.28.46,1.25,1.22s-.48,1.17-1.33,1.14c-1.74-.06-3.47-.16-5.3-.25a10.31,10.31,0,0,1-2.88,5.79l-11.47-13.09L333,119.44l11.57,13.25a8.11,8.11,0,0,1-5.13,2.12c-.86,0-1.14.32-1.09,1.19.08,1.29.05,2.58.05,3.87,0,1.13-.5,1.79-1.35,1.77s-1.2-.55-1.2-1.79c0-1.5,0-3,0-4.52a1.12,1.12,0,0,0-.68-1.12c-1.3-.8-2.53-1.73-3.95-2.72L329.57,133l-1,.92c-.84.76-1.53.84-2.05.26s-.38-1.22.49-2l2.88-2.65c-1.27-1.44-2.48-2.84-3.72-4.21a1.38,1.38,0,0,0-.83-.23c-1.26-.09-2.52-.15-3.78-.22-.92-.05-1.37-.46-1.36-1.24s.43-1.14,1.41-1.11c1.29,0,2.58.17,3.87.17a2.11,2.11,0,0,0,1.27-.49q5.6-5.07,11.15-10.2a2,2,0,0,0,.52-1.17c.13-1.28.15-2.58.22-3.86,0-.24,0-.58.17-.7.37-.29.85-.7,1.23-.64a1.39,1.39,0,0,1,.87,1,27.75,27.75,0,0,1-.15,3.86,2.29,2.29,0,0,0,.66,2C342.49,113.63,343.49,114.85,344.57,116.09Z"
                transform="translate(-0.4 -3.66)"
                fill="url(#linear-gradient-15)"
              />
              <path
                d="M335,110.94l-9.22,8.44a6,6,0,0,1-1.52-5.16A6.16,6.16,0,0,1,335,110.94Z"
                transform="translate(-0.4 -3.66)"
                fill="url(#linear-gradient-16)"
              />
            </svg>
          </ScrollAnimation>
          <div className="item1">
            <ScrollAnimation
              animateIn="slide-in-bottom"
              duration={0.3}
              delay={100}
              animateOnce={true}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://spotify-78866.firebaseapp.com/"
              >
                {/* <img src={image5} alt="After Effects" /> */}
                <div className="card">
                  <div className="card-header batman">
                    <i className="fab fa-angular"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">SPOTIFY</h5>
                    <p className="card-para">
                      A redesigned PWA clone of Spotify
                    </p>
                  </div>
                </div>
              </a>
            </ScrollAnimation>
          </div>
          <div className="item1">
            <ScrollAnimation
              animateIn="slide-in-bottom"
              duration={0.3}
              delay={100}
              animateOnce={true}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://shapeit-88ffe.web.app/"
              >
                {/* <img src={image5} alt="After Effects" /> */}
                <div className="card">
                  <div className="card-header batman">
                    <i className="fab fa-angular"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">SHAPE IT</h5>
                    <p className="card-para">
                      A Shape builder tool built with Angular
                    </p>
                  </div>
                </div>
              </a>
            </ScrollAnimation>
          </div>

          <div className="item1">
            <ScrollAnimation
              animateIn="slide-in-bottom"
              duration={0.3}
              delay={100}
              animateOnce={true}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://chennaichefs.firebaseapp.com/"
              >
                {/* <img src={image5} alt="After Effects" /> */}
                <div className="card">
                  <div className="card-header batman">
                    <i className="fab fa-react"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">CHENNAI CHEFS</h5>
                    <p className="card-para">
                      A sample restaurant app built using React and Redux
                    </p>
                  </div>
                </div>
              </a>
            </ScrollAnimation>
          </div>

          <div className="item1">
            <ScrollAnimation
              animateIn="slide-in-bottom"
              duration={0.3}
              delay={100}
              animateOnce={true}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://deepumoses.github.io/calculator/"
              >
                {/* <img src={image5} alt="After Effects" /> */}
                <div className="card">
                  <div className="card-header batman">
                    <i className="fas fa-calculator"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">CALCULATOR</h5>
                    <p className="card-para">
                      A basic Calculator app built using Pure HTML5, CSS3 and
                      Javascript(ES6)
                    </p>
                  </div>
                </div>
              </a>
            </ScrollAnimation>
          </div>

          <div className="item2">
            <ScrollAnimation
              animateIn="slide-in-bottom"
              duration={0.3}
              delay={100}
              animateOnce={true}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://codepen.io/dpkmos"
              >
                {/* <img src={image2} alt="Codepen" /> */}
                <div className="card">
                  <div className="card-header batman">
                    <i className="fab fa-codepen"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">CODEPEN</h5>
                    <p className="card-para">
                      This is where I unleash my creativity
                    </p>
                  </div>
                </div>
              </a>
            </ScrollAnimation>
          </div>

          <div className="item3">
            <ScrollAnimation
              animateIn="slide-in-bottom"
              duration={0.3}
              delay={100}
              animateOnce={true}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.behance.net/deepakmoses"
              >
                {/* <img src={image3} alt="Behance" /> */}
                <div className="card">
                  <div className="card-header batman">
                    <i className="fab fa-behance"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">BEHANCE</h5>
                    <p className="card-para">
                      Who says a developer shouldnt design
                    </p>
                  </div>
                </div>
              </a>
            </ScrollAnimation>
          </div>

          <div className="item4">
            <ScrollAnimation
              animateIn="slide-in-bottom"
              duration={0.3}
              delay={100}
              animateOnce={true}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://youtu.be/xAQo9uB2v68"
              >
                {/* <img src={image4} alt="After Effects" /> */}
                <div className="card">
                  <div className="card-header batman">
                    <i className="fab fa-youtube"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">AFTER EFFECTS</h5>
                    <p className="card-para">
                      Animations are fun even if its your name
                    </p>
                  </div>
                </div>
              </a>
            </ScrollAnimation>
          </div>
          <div className="item5">
            <ScrollAnimation
              animateIn="slide-in-bottom"
              duration={0.3}
              delay={100}
              animateOnce={true}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://youtu.be/JbywDAxKGNo"
              >
                {/* <img src={image5} alt="After Effects" /> */}
                <div className="card">
                  <div className="card-header batman">
                    <i className="fab fa-youtube"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">AFTER EFFECTS</h5>
                    <p className="card-para">
                      A short film title intro made for a friend
                    </p>
                  </div>
                </div>
              </a>
            </ScrollAnimation>
          </div>
          <div className="item6">
            <ScrollAnimation
              animateIn="slide-in-bottom"
              duration={0.3}
              delay={100}
              animateOnce={true}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://dmworldsoft.blogspot.com/"
              >
                {/* <img src={image1} alt="Blog" /> */}
                <div className="card">
                  <div className="card-header batman">
                    <i className="fab fa-blogger"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">BLOG</h5>
                    <p className="card-para">A blog for tech savvy people</p>
                  </div>
                </div>
              </a>
            </ScrollAnimation>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Gallery;
