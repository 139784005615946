import React, { Component } from "react";
import { Link } from "react-scroll";

class MenuBar extends Component {
  render() {
    return (
      <div>
        {/* <TabView view={this.state.currentTitle} /> */}

        <div className="bar">
          <Link
            activeClass="active"
            className="test1"
            to="section1"
            spy={true}
            smooth={true}
            duration={500}
          >
            <span className="bar-item green-bar-item">
              <i className="fas fa-home"></i>
            </span>
          </Link>
          <Link
            activeClass="active"
            className="test1"
            to="section2"
            spy={true}
            smooth={true}
            duration={500}
          >
            <span className="bar-item orange-bar-item">
              <i className="fas fa-user"></i>
            </span>
          </Link>
          <Link
            activeClass="active"
            className="test1"
            to="section3"
            spy={true}
            smooth={true}
            duration={500}
          >
            <span className="bar-item red-bar-item">
              <i className="fas fa-briefcase"></i>
            </span>
          </Link>
          <Link
            activeClass="active"
            className="test1"
            to="section4"
            spy={true}
            smooth={true}
            duration={500}
          >
            <span className="bar-item blue-bar-item">
              <i className="fas fa-phone"></i>
            </span>
          </Link>
        </div>
      </div>
    );
  }
}

export default MenuBar;
