import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Component } from "react";
import { render } from "react-dom";
import BackgroundCanvas from "./components/BackgroundCanvas";
import Contact from "./components/Contact";
import Skills from "./components/Skills";
import Work from "./components/Work";

import "./css/style.css";

class App extends Component {
  render() {
    return (
      <div>
        <BackgroundCanvas />
        <Skills />
        <Work />
        <Contact />
      </div>
    );
  }
}

render(<App />, document.getElementById("root"));
