import React, { Component } from "react";
import AboutMe from "./AboutMe";
import MenuBar from "./MenuBar";
import { Element } from "react-scroll";

class BackgroundCanvas extends Component {
  // componentDidMount() {
  //   window.VANTA.BIRDS({
  //     el: "#vantajs",
  //     color1: "#1abc9c",
  //     birdSize: 0.6,
  //     separation: 20,
  //     alignment: 89.0,
  //     cohesion: 100.0
  //   });
  // }
  render() {
    return (
      <Element name="section1">
        <div id="vantajs" style={{ minHeight: "100vh"}}>
          <AboutMe />
        </div>
        <MenuBar />
      </Element>
    );
  }
}
export default BackgroundCanvas;
