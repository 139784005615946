import React, { Component } from "react";
import { Element } from "react-scroll";
import ScrollAnimation from "react-animate-on-scroll";
import "@fortawesome/fontawesome-free/css/all.css";
import social from "../images/social.svg";

class Contact extends Component {
  render() {
    return (
      <Element name="section4">
        <ScrollAnimation
          animateIn="slide-in-top"
          duration={0.7}
          animateOnce={true}
        >
          <h1 className="section-heading">Contact me</h1>
        </ScrollAnimation>
        <br />
        <ScrollAnimation
          animateIn="slide-in-left"
          duration={0.7}
          delay={100}
          animateOnce={true}
        >
          <div className="contact">
            <img src={social} alt="Social Contact" />
            <div className="social-icons">
              <a
                href="https://www.facebook.com/sonicdeepak"
                className="fb-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f fa-5x"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/deepak-moses-5a611a79"
                className="gplus-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in fa-5x"></i>
              </a>
              <a
                href="https://twitter.com/deepakmoses1"
                className="twitter-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter fa-5x"></i>
              </a>
              <a
                href="mailto:sonicdeepak@gmail.com?Subject=Hello%20Deepak"
                className="mail-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-at fa-5x"></i>
              </a>
            </div>
          </div>
        </ScrollAnimation>
        <footer>
          Built with <span className="footer__heart">&#x2764;</span> using{" "}
          <b className="footer__react">React</b>
        </footer>
      </Element>
    );
  }
}

export default Contact;
